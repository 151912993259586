.call {
  display: grid;
  padding: 2rem;
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjusted minimum size */
  grid-gap: 20px; /* Simplified gap for consistency */
  width: 100%;
  height: calc(100vh - 6rem); /* Adjust height to account for the tray */
  box-sizing: border-box;
  position: relative;
}

@media only screen and (min-width: 600px) {
  .call {
    grid-template-columns: 1fr 1fr; /* Two equal columns for two participants */
  }
}

@media only screen and (max-width: 600px) {
  .call {
    padding: 1rem;
    grid-template-columns: 1fr; /* Single column layout for smaller screens */
  }
}

.self-view,
.remote-view {
  width: 100%;
  max-width: 100%; /* Ensure it takes up full width available */
  height: auto;
  position: relative;
  background-color: var(--dark-blue); /* Adds background color for better visibility */
  border-radius: 8px;
  overflow: hidden; /* Ensures video fits within the container */
}

.self-view video,
.remote-view video {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures video covers the area without distortion */
}

.username {
  position: absolute;
  z-index: 2;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  bottom: 0;
  left: 0;
  font-size: 14px;
  padding: 0.5em;
  border-radius: 0 0 8px 0; /* Rounded corner at the bottom left */
}

.info-box {
  background: var(--dark-blue-border);
  color: var(--white);
  width: 100%;
  max-width: 480px;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 8px;
}

@media only screen and (max-width: 600px) {
  .info-box {
    width: 100%;
    padding: 2rem 1rem;
  }
}

.room-url {
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
}
