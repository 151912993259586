@tailwind base;
@tailwind components;
@tailwind utilities;

.app {
  background-color: var(--darkest-blue);
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr 4rem;
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

header {
  width: 100%;
  grid-row: 1 / 2; /* Ensure header occupies the first row */
}

.api-error, .home-screen, .call, .hair-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.api-error {
  background: var(--dark-blue-border);
  width: 480px;
  height: 270px;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

.api-error p {
  color: var(--white);
}

.api-error h1 {
  color: var(--red-dark);
}

.api-error a,
.api-error a:visited {
  color: var(--white);
}

p {
  font-size: 16px;
  margin: 16px;
  text-align: center;
}

h1 {
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: var(--turquoise);
}

p.small {
  color: var(--dark-grey);
  font-size: 12px;
}

button {
  background-color: var(--turquoise);
  border: none;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
