.meeting-information {
  background-color: var(--white);
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid var(--grey);
  padding: 12px;
  cursor: pointer;
  position: fixed; /* Use 'fixed' to position relative to the viewport */
  top: 1rem;
  left: 0.5rem;
  max-width: 400px;
  z-index: 1001; /* Ensure it's above other elements */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
}

.meeting-information h1 {
  color: var(--dark-blue);
}
