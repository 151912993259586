.stop-recording-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .stop-recording-modal {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .stop-recording-modal h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #f63135; /* Use a noticeable color for the title */
  }
  
  .stop-recording-modal p {
    margin-bottom: 1.5rem;
    color: #555;
  }
  
  .stop-recording-modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .stop-recording-confirm-button {
    background-color: #f63135;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  .stop-recording-confirm-button:hover {
    background-color: #c92029; /* Darker red on hover */
  }
  
  .stop-recording-cancel-button {
    background-color: #ccc;
    color: #333;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  .stop-recording-cancel-button:hover {
    background-color: #aaa; /* Darker grey on hover */
  }
  