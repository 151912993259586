/* ConfirmationModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .modal-content h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: #333;
  }
  
  .modal-content p {
    margin-bottom: 1rem;
    color: #666;
  }
  
  .fluid-doc-option {
    margin-bottom: 1rem;
  }
  
  .fluid-doc-option input {
    margin-right: 0.5rem;
  }
  
  .fluid-doc-description {
    font-size: 0.875rem;
    color: #888;
    margin-top: 0.5rem;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 1.5rem;
  }
  
  .confirm-button,
  .cancel-button {
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .confirm-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: #fff;
  }
  