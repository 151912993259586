.hair-check {
  background: var(--white);
  border: 1px solid var(--grey);
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  margin: auto;
  justify-content: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.hair-check h1 {
  font-size: 1.5em; /* Increase font size for title */
  text-align: center;
  margin-bottom: 1.5rem; /* Increase space below title */
  color: var(--dark-blue);
}

.hair-check label {
  display: block;
  color: var(--dark-grey);
  font-size: 14px;
  margin: 1em 0 0.5em 0;
  line-height: 16px;
  width: 100%; /* Ensure labels align properly with inputs */
  max-width: 480px;
}

.hair-check input,
.hair-check select {
  border: 1px solid var(--grey);
  padding: 0.75em;
  border-radius: 4px;
  color: var(--dark-blue);
  width: 100%;
  max-width: 480px; /* Constrain max width for inputs */
  margin-bottom: 1rem; /* Add space between inputs */
  font-size: 14px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.hair-check button {
  width: 100%;
  max-width: 480px; /* Ensure buttons are the same width */
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.button2 {
  background-color: var(--turquoise);
  border: none;
  color: var(--white);
}

.cancel-call {
  background: var(--white);
  border: 1px solid var(--grey);
  color: var(--dark-blue);
}

.cancel-call:hover {
  background: var(--grey-lightest); /* Slightly darker background on hover */
}

.button2:hover {
  background-color: #17c1a8; /* Slightly darker turquoise on hover */
}

.hair-check div {
  width: 100%;
  max-width: 480px; /* Ensure all input containers are aligned */
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align labels and inputs */
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 480px;
  margin-bottom: 1rem;
  overflow: hidden; /* Ensure no overflow for blur */
}

.blur-toggle {
  background-color: var(--turquoise);
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 12px;
  margin-bottom: 1rem;
  color: var(--white);
  text-align: center;
  transition: background-color 0.3s ease;
}

.blur-toggle:hover {
  background-color: #17c1a8; /* Slightly darker turquoise on hover */
}

.hair-check video,
canvas {
  width: 100%;
  border-radius: 8px;
  z-index: 1;
}

.input-error {
  border-color: var(--red-dark); /* Highlight the input field in red */
}

.error-message {
  color: var(--red-dark);
  font-size: 12px;
  margin-top: 0.5rem;
}
