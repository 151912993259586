.tool {
  background: var(--white);
  position: fixed;
  top: 0; /* Positions the tool panel just below the header */
  bottom: 4rem; /* Keeps the tool panel above the tray */
  left: 0; /* Aligns the tool panel to the right side of the screen */
  overflow-y: auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--grey);
  z-index: 1002; /* Ensures it is above most other elements */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Adds shadow for better separation */
}

.tool-header {
  background: rgba(39, 110, 138, 0.1); /* Light background similar to chat header */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey);
}

.tool-header h2 {
  margin: 0;
  color: var(--black);
  font-size: 16px;
}

.tool-header button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--black);
}

@media only screen and (max-width: 600px) {
  .tool {
    width: 100%;
    z-index: 1003; /* Overlay the usernames in the Call screen */
    bottom: 7rem; /* Adjust bottom space to avoid overlapping with the tray */
  }
}

.tool-list {
  padding: 1rem;
  margin: 0;
  flex-grow: 1; /* Makes sure this area grows to fill available space */
  overflow-y: auto;
}

.tool-card {
  background: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  padding: 1rem;
  text-align: left; 
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.tool-card-title {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: var(--darkest-blue);
}

.tool-card-description {
  padding: auto;
  text-align: left; 
  color: var(--dark-grey);
  margin-bottom: 1rem;
}

.tool-card-body {
  padding: 0.5rem;
  text-align: left; 
}

.tool-card-button {
  background: var(--turquoise);
  color: var(--blue);
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.tool-card-button:hover {
  background-color:  var(--dark-blue);
  color: var(--white);
}

.tool-footer {
  border-top: 1px solid var(--grey);
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

.close-tool {
  background: var(--turquoise);
  border: 1px solid var(--grey);
  padding: 0.5rem 1rem;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-tool:hover {
  background: var(--grey);
  border: 1px solid var(--dark-blue-border);
}
