.tray {
  width: 100%;
  background: var(--white);
  color: var(--darkest-blue);
  display: flex;
  justify-content: center;
  align-items: center; /* Ensure the tray content is vertically centered */
  position: fixed; /* Fixes the tray at the bottom */
  bottom: 0; /* Positions it at the bottom of the viewport */
  left: 0;
  z-index: 1000; /* Ensures the tray is above other elements */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Adds shadow for better separation */

}

.tray-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between; /* Evenly distributes buttons */
  align-items: center; /* Aligns items in the center vertically */
  flex-wrap: nowrap; /* Prevents buttons from wrapping */
}

.tray-buttons-container button {
  padding: 0.25rem 1rem;
  background-color: white;
  display: flex;
  align-items: center; /* Align icon and text horizontally */
  justify-content: center;
  font-weight: normal;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  flex-direction: row; /* Ensure icons and text are in a row */
}

.tray-buttons-container button svg {
  color: var(--darkest-blue);
  margin-right: 0.5rem; /* Space between icon and text */
}

.tray-buttons-container button:hover {
  background-color: var(--grey-lightest); /* Adds a hover effect */
}

.controls,
.actions,
.leave {
  display: flex;
  align-items: center;
}

.controls {
  justify-content: flex-start;
}

.actions {
  justify-content: center;
}

.leave {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .tray-buttons-container {
    flex-direction: column;
    align-items: center;
  }

  .tray-buttons-container button {
    margin-bottom: 1rem;
    width: 100%; /* Make buttons full width on smaller screens */
  }

  .tray-buttons-container button + button {
    margin-left: 0;
  }
}
