.tile-video {
  position: relative;
  width: 100%; /* Make the tile take up the full width of its container */
  height: 100%; /* Make the tile take up the full height of its container */
  max-width: 480px; /* Limit the maximum width */
  max-height: 270px; /* Limit the maximum height */
  background-color: var(--dark-blue); /* Background color for better visibility */
  border-radius: 8px; /* Rounded corners for a more polished look */
  overflow: hidden; /* Ensure the video stays within the rounded corners */
}

.tile-video video {
  width: 100%; /* Make the video fill the tile */
  height: 100%; /* Make the video fill the tile */
  object-fit: cover; /* Ensure the video covers the tile without distortion */
}

@media only screen and (max-width: 600px) {
  .tile-video {
    max-width: 240px; /* Reduce the max width for smaller screens */
    max-height: 135px; /* Reduce the max height for smaller screens */
  }
}

/* When a screen is being shared, resize the video tiles */
.is-screenshare .tile-video {
  max-width: 240px;
  max-height: 135px;
}

/* Screen share tile takes up full grid area */
.tile-screenshare {
  grid-column: 1 / -1; /* Span across all columns */
  grid-row: 1; /* Take the first row */
  width: 100%; /* Make the screen share tile take up full width */
  height: 100%; /* Make the screen share tile take up full height */
}

.username {
  position: absolute;
  z-index: 2;
  color: var(--white);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  bottom: 0;
  left: 0;
  font-size: 14px;
  padding: 0.5em;
  border-radius: 0 0 8px 0;
}
