.chat {
  background: var(--white);
  position: fixed; /* Changed from 'absolute' to 'fixed' for viewport positioning */
  top: 0rem; /* Positions the chat just below the header */
  bottom: 4rem; /* Keeps the chat above the tray */
  left: 0; /* Aligns the chat to the right side of the screen */
  overflow-y: auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid var(--grey);
  z-index: 1002; /* Ensures it is above most other elements */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1); /* Adds shadow for better separation */
}

.chat-header {
  background:   rgba(39, 110, 138, 0.1);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey);
}

.chat-header h2 {
  margin: 0;
  color: var(--black);
  font-size: 16px;
}

.chat-header button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--black);
}

@media only screen and (max-width: 600px) {
  .chat {
    width: 100%;
    z-index: 1003; /* Overlay the usernames in the Call screen */
    bottom: 7rem; /* Adjust bottom space to avoid overlapping with the tray */
  }
}

.add-message {
  padding: 1rem;
  border-top: 1px solid var(--grey);
  border-bottom: 1px solid var(--grey);
}

.chat-messages {
  padding: 1rem;
  margin: 0;
  flex-grow: 1; /* Makes sure this area grows to fill available space */
  overflow-y: auto;
}

.chat-message {
  list-style-type: none;
  margin-bottom: 1rem;
}

.chat-message-author {
  font-weight: bold;
  font-size: 14px;
}

.chat-message-body {
  text-align: left;
  margin: 0;
  display: inline;
  color: var(--dark-grey);
  font-size: 14px;
}

.chat-form {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--grey);
}

.chat-input {
  background: transparent;
  border: none;
  flex-grow: 1;
  padding: .5rem;
  font-size: 14px;
}

.chat-submit-button {
  background: var(--turquoise);
  border: none;
  padding: 0.5rem;
  color: var(--white);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.chat-submit-button:hover {
  background-color: var(--dark-turquoise);
}

.close-chat {
  background: var(--turquoise);
  border: 1px solid var(--grey);
  padding: .5rem 1rem;
  margin: 1rem;
  align-self: end;
  font-weight: normal;
}

.close-chat:hover {
  background: var(--grey);
  border: 1px solid var(--dark-blue-border);
}
