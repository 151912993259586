.home-screen {
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0; /* Ensure there is no margin */
  min-height: 100vh; /* Cover full viewport height */
  text-align: center;
  background: linear-gradient(135deg, #18bba0, #054c5f); /* Subtler gradient background */
}


.home-screen h1 {
  color: #ffffff; /* White color for better contrast */
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 2rem;
  max-width: 600px; /* Restrict width for better readability */
}

.home-screen p {
  color: #d1e0e5; /* Light grey for subtler text */
  margin-bottom: 1rem;
  font-size: 1rem;
  max-width: 500px; /* Restrict width for better readability */
}

.button1 {
  background-color: #17d1b3; /* Bright turquoise for the button */
  border: none;
  border-radius: 8px;
  font-size: 16px; /* Slightly larger font size for emphasis */
  font-weight: 600;
  cursor: pointer;
  padding: 14px 28px; /* Increased padding for a more substantial button */
  margin-top: 2rem; /* Increased margin for better spacing */
  color: #ffffff; /* White text for better contrast */
  text-align: center;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Button shadow for a 3D effect */
}

.button1:hover {
  background-color: #14b89d; /* Darker turquoise on hover */
}

.home-screen .small {
  font-size: 0.9rem; /* Slightly larger small text for readability */
  color: #e6f1f3; /* Light grey */
  margin-top: 1rem; /* Increased margin for spacing */
}

.back-to-start {
  font-size: 14px;
  color: #ffffff;
  margin-top: 1.5rem;
  cursor: pointer;
  text-decoration: underline;
}
